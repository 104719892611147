import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import StorageService from './storage.service';

interface CustomError {
	status: number;
	message: string;
	data?: any;
};

// handle logout globally
const handleLogout = () => {
	const storage = new StorageService();
	storage.clear();
	window.location.href = '/';
};

// Create an Axios instance
const axiosInstance = axios.create({
	baseURL: 'https://api.flexad.info/flex-api',
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		'Accept': 'application/json, text/plain',
		'Content-Type': 'application/json;charset=UTF-8'
	},
});

// Request interceptor to add authorization token
axiosInstance.interceptors.request.use(
	(config: any) => {
		// Get token from local storage or any other storage
		const tokenType = localStorage.getItem('TOKEN_TYPE');
		const token = localStorage.getItem('AUTH_TOKEN');
		if (tokenType && token && config.headers) {
			config.headers.Authorization = `${tokenType} ${token}`;
		}
		return config;
	},
	(error: Error) => {
		return Promise.reject(error);
	}
);

// Response interceptor for error handling (optional)
axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	(error: AxiosError) => {
		const customError: CustomError = {
			status: error.response?.status || 500,
			message: 'Something went wrong',
			data: error.response?.data
		};

		switch (error.response?.status) {
			case 400:
				customError.message = 'Bad Request';
				break;
			case 401:
				customError.message = 'Unauthorized';
				// Handle unauthorized access
				handleLogout();
				break;
			case 403:
				customError.message = 'Forbidden';
				break;
			case 404:
				customError.message = 'Not Found';
				break;
			case 408:
				customError.message = 'Request Timeout';
				break;
			case 500:
				customError.message = 'Internal Server Error';
				break;
			case 502:
				customError.message = 'Bad Gateway';
				break;
			case 503:
				customError.message = 'Service Unavailable';
				break;
			default:
				customError.message = error.message || 'Something went wrong';
		}

		// You can add toast notifications here
		console.error(`Error ${customError.status}: ${customError.message}`);

		return Promise.reject(customError);
	}
);

export const axiosBaseQuery = (): BaseQueryFn<
	{ url: string; method: AxiosRequestConfig['method']; data?: any; params?: any },
	unknown,
	unknown
> => async ({ url, method, data, params }: any) => {
	try {
		const result = await axiosInstance({ url, method, data, params });
		return { data: result.data };
	} catch (axiosError) {
		const err = axiosError as CustomError;
		return {
			error: {
				status: err.status,
				data: err.data || err.message,
			},
		};
	}
};

// Helper function to handle API responses
export const handleApiResponse = async <T>(
	apiCall: Promise<AxiosResponse<T>>
): Promise<T> => {
	try {
		const response = await apiCall;
		return response.data;
	} catch (error) {
		throw error;
	}
};

export default axiosInstance;
