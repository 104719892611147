import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import { FENCE_API } from '../../lib/apiroutes';

export const fenceApi = createApi({
  reducerPath: 'fence',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSolarEnergy: builder.mutation({
      query: (payload) => ({
        url: `${FENCE_API.GET_SOLAR_ENERGY}`,
        method: 'POST',
        data: payload
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getQuote: builder.mutation({
      query: (payload) => ({
        url: `${FENCE_API.GET_QUOTE}`,
        method: 'POST',
        data: payload
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetSolarEnergyMutation,
  useGetQuoteMutation,
} = fenceApi;
