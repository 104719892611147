import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { appRoutes } from '../lib/routes';

const Home = lazy(() => import('../pages/home/Home'));
const Configurator = lazy(() => import('../pages/configurator/Configurator'));
const NotFound = lazy(() => import('../components/not-found/NotFound'));

const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback={<div>Laden...</div>}>
      <Routes>
        <Route path={appRoutes.default} element={<Home />} />
        <Route path={appRoutes.configurator} element={<Configurator />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;